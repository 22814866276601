@use "sass:map";

$theme-colors: (
  "primary": #183A1D,
  "secondary": #E1EEDD,
  "danger": #952323,
  "dark": #001524,
  "warning": #FECDA6,
  "light": #FEFBE9,
  "info": #E1EEDD
);

$body-bg: map.get($theme-colors, "light");
$border-color: map.get($theme-colors, "dark");

.amplify-grid {
  padding: 0 !important;
  margin: 0 !important;
}

select {
    border: 0;
}

*:focus {
    outline: none !important;
    box-shadow: none !important;
    border-color: inherit !important;
}

.row .py-4 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
}


button.amplify-button--primary {
    background-color: map.get($theme-colors, "primary") !important;
    color: #FFF !important;
}

.amplify-tabs-item[data-state=active] {
    color: #000 !important;
    border-Color: #000 !important;
}

.amplify-button--link {
    color: #000 !important;
    text-decoration: underline !important;
}

.form-label {
    font-weight: 600;
}

.rjsf-form-dropdown-menu .dropdown-menu {
    max-height: 300px;
    overflow-y: scroll;
}

[data-amplify-theme="default-theme"] {
    --amplify-colors-background-primary: #FEFBE9 !important;
    --amplify-components-authenticator-state-inactive-background-color: #FEFBE9 !important;
}

@import 'bootstrap/scss/bootstrap';